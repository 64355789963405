import AddIcon from "@mui/icons-material/AddRounded";
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Avatar, Box, Button, Color, Stack, Tooltip, Typography } from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import { GridColDef } from "@mui/x-data-grid-premium";
import HorizontalFill from "../../../../../../../shared/components/HorizontalFill";
import { convertISODate } from "../../../../../../../shared/utilities/dateUtils";
import { formatNumber } from "../../../../../../../shared/utilities/formatters";
import { ScenarioTableBlockColumn, TableBlockColumn, TableBlockRow } from "../../../../../../api/types/inputFormTypes";
import { usePortfolioMonitoringInputTableContext } from "./PortfolioMonitoringInputTableContext";
import { calculateTotals } from "./tableTotalsCalculator";

export type RowModel = TableBlockRow & {
  validationError?: string;
};

const scenarioColorMap: Record<string, Color> = {
  Actual: blue,
  Budget: orange,
};

const ScenarioAvatar = ({ scenarioName }: { scenarioName: string }) => {
  if (!scenarioName) {
    return <Box width={16} height={16} />;
  }

  const color = scenarioColorMap[scenarioName] ?? grey;

  return (
    <Tooltip arrow title={scenarioName}>
      <Avatar sx={{ width: 16, height: 16, bgcolor: color[50], color: color[700] }}>
        <Typography variant="caption" fontWeight={500}>
          {scenarioName[0]}
        </Typography>
      </Avatar>
    </Tooltip>
  );
};

const ScenarioColumnHeader = ({ columnDefinition }: { columnDefinition: ScenarioTableBlockColumn }) => {
  return (
    <Stack width="100%">
      <Box display="flex" justifyContent="space-between">
        <ScenarioAvatar scenarioName={columnDefinition.scenarioName} />
        <Typography variant="subtitle2">{convertISODate(columnDefinition.titleAsDate, "MMM yyyy")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <HorizontalFill />
        <Typography variant="caption" color="text.secondary">
          {columnDefinition.subtitle}
        </Typography>
      </Box>
    </Stack>
  );
};

const NameCell = ({ row }: { row: RowModel }) => {
  const { onAddMetricExtension } = usePortfolioMonitoringInputTableContext();

  if (row.type === "Metric") {
    return (
      <>
        <Typography noWrap pl={row.indentationLevel * 2} flexGrow={1}>
          {row.name}
        </Typography>
        {row.validationError && (
          <Tooltip title={row.validationError}>
            <ErrorIcon color="error" />
          </Tooltip>
        )}
      </>
    );
  }

  if (row.type === "ExtendedMetricSection") {
    return (
      <>
        <Typography variant="subtitle2" noWrap pl={row.indentationLevel * 2} flexGrow={1}>
          {row.name}
        </Typography>
        <Button variant="text" startIcon={<AddIcon />} onClick={() => onAddMetricExtension(row.id)} tabIndex={-1}>
          Add
        </Button>
      </>
    );
  }

  return (
    <Typography variant="subtitle2" noWrap pl={row.indentationLevel * 2}>
      {row.name}
    </Typography>
  );
};

const formatNumberValue = (value: number) => formatNumber(value, { maximumFractionDigits: 0 });

const ValuesDiff = ({ previous, current }: { previous: number; current: number }) => {
  return (
    <Typography component="span">
      <Typography component="span" bgcolor={red[50]} sx={{ textDecoration: "line-through" }}>
        {formatNumberValue(previous)}
      </Typography>
      <Typography component="span" ml={1} bgcolor={green[50]}>
        {formatNumberValue(current)}
      </Typography>
    </Typography>
  );
};

export const getColumns = (columnDefinitions: TableBlockColumn[]): GridColDef<RowModel>[] => {
  const scenarioColumnDefinitions = columnDefinitions.filter((column) => column.type === "Scenario");

  const metricColumn: GridColDef<RowModel> = {
    field: "name",
    headerName: "Metric",
    flex: 2,
    minWidth: 300,
    editable: true,
    cellClassName: ({ row }) => {
      if (row.type === "Total") {
        return "total-cell";
      }

      if ((row.type === "Metric" || row.type === "ExtendedMetricSection") && row.isSecondary) {
        return "secondary-name-cell";
      }

      return "";
    },
    renderCell: ({ row }) => <NameCell row={row} />,
  };

  const scenarioColumns: GridColDef<RowModel>[] = scenarioColumnDefinitions.map((columnDef) => ({
    field: columnDef.id,
    flex: 1,
    minWidth: 150,
    type: "number",
    editable: columnDef.isEditable,
    colSpan: (_, row) => (row.type === "Section" || row.type === "ExtendedMetricSection" ? 8 : 0),
    renderHeader: () => <ScenarioColumnHeader columnDefinition={columnDef} />,
    headerClassName: () => (columnDef.isEditable ? "" : "readonly-header"),
    cellClassName: ({ row }) => {
      if (row.type === "Total") {
        return "total-cell";
      }

      if (row.type === "Metric" && !columnDef.isEditable) {
        return "readonly-cell";
      }

      return "";
    },
    valueGetter: (_, row) =>
      row.type === "Total" ? row.values[columnDef.id] : row.type === "Metric" ? row.values[columnDef.id] : undefined,
    valueSetter: (newValue, row) => {
      if (row.type !== "Metric") {
        return row;
      }

      return { ...row, values: { ...row.values, [columnDef.id]: newValue } };
    },
    valueFormatter: (value) => {
      if (!value) {
        return "-";
      }
      if (typeof value !== "number") {
        return value;
      }
      return formatNumberValue(value);
    },
    renderCell:
      columnDef.isEditable && columnDef.dateRange === "Previous"
        ? ({ row, formattedValue, value }) => {
            if (row.type !== "Metric" || typeof value !== "number") {
              return formattedValue;
            }

            const previousValue = row.previousValues[columnDef.id];
            if (!previousValue) {
              return formattedValue;
            }

            return <ValuesDiff previous={previousValue} current={value} />;
          }
        : undefined,
  }));

  return [metricColumn, ...scenarioColumns];
};

export const getRows = (rows: TableBlockRow[]): RowModel[] => {
  return calculateTotals(rows);
};
