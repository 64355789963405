import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";

const PageDeactivatedAlert = () => {
  const { clientCode } = useClientContext();

  return (
    <Alert severity="warning" sx={{ position: "relative" }}>
      <AlertTitle>Fundraising page is not activated</AlertTitle>
      <Button
        component={Link}
        variant="text"
        color="inherit"
        sx={{ position: "absolute", right: 12, top: 12, py: 0 }}
        to={`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.settings}?tab=pages`}
      >
        Open Settings
      </Button>
      <Typography>
        For fundraisings to show up on the portal, you need to enable the Fundraising page in Investor Portal Settings.
      </Typography>
    </Alert>
  );
};

export default PageDeactivatedAlert;
