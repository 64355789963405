import { Grid } from "@mui/material";
import { useCallback } from "react";
import { FormCellValue, FormRowExtensionRename } from "../../../../../api/types/dataCollectionTypes";
import { DataSubmissionLayout, ResolvedBlockContent } from "../../../../../api/types/inputFormTypes";
import DataSubmissionFormBlock from "./DataSubmissionFormBlock";
import { DataSubmissionFormContextProvider } from "./DataSubmissionFormContext";

interface Props {
  layout: DataSubmissionLayout;
  blockContents: ResolvedBlockContent[];
  hasPermissionsToEdit: boolean;
  onBlockCellValueEdit: (blockId: string, formCellValue: FormCellValue) => void;
  onBlockRowExtensionRename: (blockId: string, formRowExtensionRename: FormRowExtensionRename) => void;
}

const DataSubmissionForm = ({
  layout,
  blockContents,
  hasPermissionsToEdit,
  onBlockCellValueEdit,
  onBlockRowExtensionRename,
}: Props) => {
  const getBlockContent = useCallback(
    (blockId: string) => blockContents.find((blockContent) => blockContent.blockId === blockId)?.content,
    [blockContents]
  );

  return (
    <DataSubmissionFormContextProvider
      hasPermissionsToEdit={hasPermissionsToEdit}
      getBlockContent={getBlockContent}
      onBlockCellValueEdit={onBlockCellValueEdit}
      onBlockRowExtensionRename={onBlockRowExtensionRename}
    >
      <Grid container sx={{ flex: 1, py: 2.5, px: 3 }} spacing={1}>
        {layout.blocks.map((block) => (
          <DataSubmissionFormBlock key={block.id} block={block} />
        ))}
      </Grid>
    </DataSubmissionFormContextProvider>
  );
};

export default DataSubmissionForm;
