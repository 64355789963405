import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";

interface Props {
  onAddMetricExtension: (rowId: string) => void;
}

const PortfolioMonitoringInputTableContext = createContext<Props | undefined>(undefined);

export const PortfolioMonitoringInputTableContextProvider = ({
  children,
  ...contextValue
}: PropsWithChildren<Props>) => (
  <PortfolioMonitoringInputTableContext.Provider value={contextValue}>
    {children}
  </PortfolioMonitoringInputTableContext.Provider>
);

export const usePortfolioMonitoringInputTableContext = () =>
  defined(
    useContext(PortfolioMonitoringInputTableContext),
    "Attempt to use PortfolioMonitoringInputTableContext without provider"
  );
