export const dataCollectionsNavigationViews = [
  "data_collection_requests",
  "data_submissions",
  "data_request_templates",
] as const;

export type DataCollectionsNavigationView = (typeof dataCollectionsNavigationViews)[number];

export const dataCollectionsNavigationViewsCaptionMap: Record<DataCollectionsNavigationView, string> = {
  data_collection_requests: "Data Requests",
  data_submissions: "Data Submissions",
  data_request_templates: "Request Templates",
};
