import MoneyIcon from "@mui/icons-material/AttachMoney";
import { Box, Button, Container, Divider, Stack } from "@mui/material";
import { useCallback, useReducer } from "react";
import { useLocation } from "react-router-dom";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import SearchField from "../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import { DescriptiveDate, last12Months, thisQuarter, thisYear } from "../../../../shared/utilities/dateFilterHelper";
import adminApi from "../../../api/adminApi";
import { useClientContext } from "../../../context/ClientContext";
import DateRangeSelect from "../../common/DateRangeSelect";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import MultiSelect from "../../common/MultiSelect";
import DocumentCollectionsTable from "./DocumentCollectionsTable";
import { defaultDateRange, getInitialState, reducer } from "./state";

const DocumentCollections = () => {
  const { clientCode } = useClientContext();
  const { state: navigationState } = useLocation();

  const [state, dispatch] = useReducer(reducer, getInitialState(clientCode));

  const getDocumentCollections = useCallback(
    () => adminApi.getDocumentCollections({ dateFrom: state.dateRange.from, dateTo: state.dateRange.to }),
    [state.dateRange.from, state.dateRange.to]
  );

  const [resp, fetchError, { isFetching }] = useFetch(getDocumentCollections, (data) =>
    dispatch({ type: "LOAD_DATA", items: data.documentCollections })
  );

  if (fetchError) {
    logError(fetchError, "[DocumentCollections]");
    return <DataLoadingFailed title="Loading document collections failed" />;
  }

  const handleSearch = (searchTerm: string) => dispatch({ type: "SEARCH", searchTerm });

  const handleDateRangeChange = (dateRange: DescriptiveDate) =>
    dispatch({ type: "SET_DATE_RANGE", dateRange, clientCode });

  const handleFundsFilterChange = (selectedFunds: string[]) => dispatch({ type: "FILTER_FUNDS", selectedFunds });

  const handleClearFilters = () => dispatch({ type: "CLEAR_FILTERS", clientCode });

  const showClearFilters =
    state.dateRange.from !== defaultDateRange.from ||
    state.dateRange.to !== defaultDateRange.to ||
    state.selectedFunds.length > 0;

  const isLoading = resp === undefined || isFetching;

  return (
    <>
      <GeneralPageHeader title="Document Collections" />
      <Container maxWidth={false} sx={{ height: "100%", py: 2.5 }}>
        <Box display="flex" flexDirection="column" width="100%" height="100%" pb={5}>
          <Stack mt={1} mb={1} direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <DateRangeSelect
                predefinedRanges={[last12Months, thisQuarter, thisYear]}
                customRangeTitle="Custom date range"
                dateRange={state.dateRange}
                onChange={handleDateRangeChange}
                disabled={isLoading}
              />
              <Divider orientation="vertical" flexItem />
              <MultiSelect
                placeholder="Funds"
                options={state.allFunds.map((fundName) => ({ value: fundName, label: fundName }))}
                selectedValues={state.selectedFunds}
                onChange={handleFundsFilterChange}
                StartIcon={MoneyIcon}
                disabled={isLoading}
              />
              {showClearFilters && (
                <Button variant="text" color="primary" onClick={handleClearFilters}>
                  Clear All
                </Button>
              )}
            </Stack>

            <SearchField
              initialValue={state.searchTerm}
              onSearch={handleSearch}
              disabled={isLoading}
              debounceTimeMs={300}
            />
          </Stack>

          <DocumentCollectionsTable
            documentCollections={state.filteredItems}
            loading={resp === undefined || isFetching}
            selectedId={navigationState?.documentCollectionId}
          />
        </Box>
      </Container>
    </>
  );
};

export default DocumentCollections;
