import { PagingParams } from "../../../shared/api/types";
import { DataSubmissionLayout, ResolvedBlockContent } from "./inputFormTypes";

export interface DataCollectionSubmissionInfo {
  id: string;
  recipientName: string;
  status: DataCollectionSubmissionStatus;
  requestTemplateId: string;
  requestTemplateName: string;
  reportingDate?: string;
  dueDate: string;
  submittedBy?: string;
  submittedAt?: string;
}

export interface DataCollectionSubmissionDetails {
  id: string;
  dataCollectionRequestId: string;
  recipientObjectId: string;
  recipientName: string;
  status: DataCollectionSubmissionStatus;
  requestTemplateId: string;
  requestTemplateName: string;
  reportingDate: string;
  dueDate: string;
  submittedBy?: string;
  submittedAt?: string;
  blockContents: ResolvedBlockContent[];
  layout: DataSubmissionLayout;
}

export const allDataCollectionSubmissionStatuses = ["Draft", "Pending", "Completed", "ChangesRequested"] as const;
export type DataCollectionSubmissionStatus = (typeof allDataCollectionSubmissionStatuses)[number];

export interface SaveSubmissionLayoutBlockInputRequest {
  formCellValues: FormCellValue[];
  formRowExtensionRenames: FormRowExtensionRename[];
}

export interface FormCellValue {
  rowId: string;
  rowExtensionValue?: string;
  columnId: string;
  value: unknown;
}

export interface FormRowExtensionRename {
  rowId: string;
  oldRowExtensionValue: string;
  newRowExtensionValue: string;
}

export interface DataSubmissionConfigurationInfo {
  id: string;
  name: string;
}

export interface DataCollectionRequestInfo {
  id: string;
  name: string;
  templateId: string;
  templateName: string;
  auditory: DataCollectionRequestAuditory;
  status: DataCollectionRequestStatus;
  reportingDate: string;
  createdAt: string;
  publishedAt?: string;
  submittedSubmissions: number;
  totalSubmissions: number;
}

export interface DataCollectionRequestDetails {
  id: string;
  name: string;
  templateId: string;
  templateName: string;
  auditory: DataCollectionRequestAuditory;
  status: DataCollectionRequestStatus;
  reportingDate: string;
  createdAt: string;
  publishedAt?: string;
  submittedSubmissions: number;
  submissions: RequestSubmissionInfo[];
  scope: DataCollectionRequestScope;
  templateVersion: number;
  templateScenario: string;
  templateReportingPeriod: ReportingPeriod;
  recipientObjectIds: string[];
  dueDate: string;
}

export interface RequestSubmissionInfo {
  id: string;
  status: DataCollectionSubmissionStatus;
  dueDate: string;
  recipientObjectId: string;
  recipientName: string;
  recipientContacts: RequestSubmissionContact[];
  submittedAt?: string;
  submittedByUserName?: string;
}

export interface SearchSubmissionsRequest {
  paging: PagingParams;
  searchTerm?: string;
  requestTemplateIds?: string[];
  recipientObjectIds?: string[];
  statuses?: DataCollectionSubmissionStatus[];
  reportingDateFrom?: string;
  reportingDateTo?: string;
}

export interface RequestSubmissionContact {
  contactId: string;
  contactName: string;
}

export const allDataCollectionRequestAuditoryValues = ["PortfolioCompany"] as const;
export type DataCollectionRequestAuditory = (typeof allDataCollectionRequestAuditoryValues)[number];

export const allDataCollectionRequestStatuses = ["Draft", "InProgress", "Completed"] as const;
export type DataCollectionRequestStatus = (typeof allDataCollectionRequestStatuses)[number];

export interface SearchDataCollectionRequestRequest {
  paging: PagingParams;
  searchTerm?: string;
  templateIds?: string[];
  statuses?: DataCollectionRequestStatus[];
  auditory?: DataCollectionRequestAuditory[];
  reportingDateFrom?: string;
  reportingDateTo?: string;
}

export interface CreateDataCollectionRequestRequest {
  templateId: string;
  name: string;
  reportingDate: string;
  dueDate: string;
  auditory: DataCollectionRequestAuditory;
}

export interface UpdateDataCollectionRequestRequest {
  name?: string;
  reportingDate?: string;
  dueDate?: string;
}

export interface DataCollectionRequestTemplateVersion {
  id: string;
  name: string;
  version: number;
  templateId: string;
  scenarioName: string;
  scenarioId: string;
  auditory: DataCollectionRequestAuditory;
  recipientObjectIds: string[];
  reportingPeriod: ReportingPeriod;
  scope: DataCollectionRequestScope;
}

export type ReportingPeriod = "Month" | "Quarter" | "Year" | "LastTwelveMonths";

type DataCollectionRequestScope = "PortfolioMonitoring";

export interface RequestSubmissionOperationRequest {
  submissionIds: string[];
}

export interface AddRequestSubmissionsRequest {
  recipientObjectIds: string[];
}
