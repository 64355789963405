import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../shared/utilities/typeHelper";
import { DataCollectionRequestTemplateVersion } from "../../../api/types/dataCollectionTypes";

interface Props {
  hasPermissionsToManage: boolean;
  templates: DataCollectionRequestTemplateVersion[];
}

const DataCollectionsPageContext = createContext<Props | undefined>(undefined);

export const DataCollectionsPageContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <DataCollectionsPageContext.Provider value={contextValue}>{children}</DataCollectionsPageContext.Provider>
);

export const useDataCollectionsPageContext = () =>
  defined(useContext(DataCollectionsPageContext), "Attempt to use DataCollectionsPageContext without provider");
