import { isBefore, isValid, parseISO, startOfToday } from "date-fns";
import { formatDate } from "../../../../../../shared/utilities/dateUtils";
import {
  CreateDataCollectionRequestRequest,
  DataCollectionRequestDetails,
  UpdateDataCollectionRequestRequest,
} from "../../../../../api/types/dataCollectionTypes";

export interface EditDataRequestForm {
  templateId: string;
  name: string;
  reportingDate: Date | null;
  dueDate: Date | null;
  touchedFields: ValidationFieldKey[];
  editedDataRequest?: DataCollectionRequestDetails;
}

const validationFieldKeys = ["templateId", "name", "reportingDate", "dueDate"] as const;
export type ValidationFieldKey = (typeof validationFieldKeys)[number];

interface FormValidationResult {
  isFormValid: boolean;
  validationErrors: Partial<Record<ValidationFieldKey, string | undefined>>;
}

export const getInitialEditDataRequestForm = (
  editedDataRequest: DataCollectionRequestDetails | undefined
): EditDataRequestForm => ({
  templateId: editedDataRequest?.templateId ?? "",
  name: editedDataRequest?.name ?? "",
  reportingDate: editedDataRequest?.reportingDate ? parseISO(editedDataRequest.reportingDate) : null,
  dueDate: editedDataRequest?.dueDate ? parseISO(editedDataRequest.dueDate) : null,
  touchedFields: [],
  editedDataRequest,
});

export const formToCreateRequestPayload = (
  form: EditDataRequestForm
): CreateDataCollectionRequestRequest | undefined => {
  if (
    !form.templateId ||
    !form.name ||
    !form.reportingDate ||
    !isValid(form.reportingDate) ||
    !form.dueDate ||
    !isValid(form.dueDate) ||
    isBefore(form.dueDate, startOfToday())
  ) {
    return undefined;
  }

  return {
    templateId: form.templateId,
    name: form.name.trim(),
    reportingDate: formatDate(form.reportingDate, "yyyy-MM-dd"),
    dueDate: formatDate(form.dueDate, "yyyy-MM-dd"),
    auditory: "PortfolioCompany",
  };
};

export const formToUpdateRequestPayload = (
  form: EditDataRequestForm
): UpdateDataCollectionRequestRequest | undefined => {
  if (
    !form.name ||
    !form.reportingDate ||
    !isValid(form.reportingDate) ||
    !form.dueDate ||
    !isValid(form.dueDate) ||
    isBefore(form.dueDate, startOfToday())
  ) {
    return undefined;
  }

  const name = form.name.trim();
  const reportingDate = formatDate(form.reportingDate, "yyyy-MM-dd");
  const dueDate = formatDate(form.dueDate, "yyyy-MM-dd");

  return {
    name: name === form.editedDataRequest?.name ? undefined : name,
    reportingDate: reportingDate === form.editedDataRequest?.reportingDate ? undefined : reportingDate,
    dueDate: dueDate === form.editedDataRequest?.dueDate ? undefined : dueDate,
  };
};

export const validateForm = (form: EditDataRequestForm): FormValidationResult => {
  const validationErrors: Partial<Record<ValidationFieldKey, string | undefined>> = {};

  if (!form.templateId) {
    validationErrors.templateId = "Template is required";
  }

  if (!form.name) {
    validationErrors.name = "Name is required";
  }

  if (!form.reportingDate || !isValid(form.reportingDate)) {
    validationErrors.reportingDate = "Reporting date is required";
  }

  if (!form.dueDate || !isValid(form.dueDate)) {
    validationErrors.dueDate = "Due date is required";
  } else if (isBefore(form.dueDate || new Date(), startOfToday())) {
    validationErrors.dueDate = "Due date must not be in the past";
  }

  const isFormValid = Object.values(validationErrors).every((e) => !e);

  validationFieldKeys.forEach((key) => {
    if (!form.touchedFields.includes(key)) {
      validationErrors[key] = undefined;
    }
  });

  return {
    isFormValid,
    validationErrors,
  };
};
