import { Stack, Typography } from "@mui/material";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { DataCollectionSubmissionDetails } from "../../../../../api/types/dataCollectionTypes";
import DataSubmissionStatusTag from "../DataSubmissionStatusTag";

interface Props {
  dataSubmission: DataCollectionSubmissionDetails;
}

const DataSubmissionPageTitle = ({ dataSubmission }: Props) => {
  return (
    <Stack py={1.5} spacing={0.5}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h6" noWrap>
          {dataSubmission.requestTemplateName}
        </Typography>
        <Typography variant="h6">•</Typography>
        <Typography variant="h6" noWrap>
          {dataSubmission.recipientName}
        </Typography>
        <DataSubmissionStatusTag status={dataSubmission.status} />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="caption" color="text.secondary">
          Data Submission
        </Typography>
        <Typography variant="caption">•</Typography>
        <Typography variant="caption" color="text.secondary">
          Due Date:
        </Typography>
        <Typography variant="caption">{convertISODate(dataSubmission.dueDate)}</Typography>
      </Stack>
    </Stack>
  );
};

export default DataSubmissionPageTitle;
