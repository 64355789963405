import { Typography } from "@mui/material";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { FundWithFieldValues } from "../../../../api/adminApi";
import { columnDefinitions } from "./fundsGridDataProvider";

interface Props {
  funds: FundWithFieldValues[];
  isLoading: boolean;
  onFundClick: (id: string) => void;
}

const FundsList = ({ funds, isLoading, onFundClick }: Props) => {
  return (
    <DataGrid<FundWithFieldValues>
      getRowId={(row) => row.id}
      loading={isLoading}
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="textSecondary">No funds</Typography>
          </CenteredWrapper>
        ),
      }}
      columns={columnDefinitions}
      rowHeight={40}
      rows={funds}
      selectableRows
      onCellClick={(params) => onFundClick(params.row.id)}
      disableRowSelectionOnClick
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnSorting
    />
  );
};

export default FundsList;
