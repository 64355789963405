import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import LanguageButton from "@mui/icons-material/LanguageRounded";
import RevertIcon from "@mui/icons-material/ReplayRounded";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import InlineItemsList from "../../../../../../shared/components/InlineItemsList";
import { distinctBy } from "../../../../../../shared/utilities/arrayHelper";
import { convertISODate, convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import { RequestSubmissionContact, RequestSubmissionInfo } from "../../../../../api/types/dataCollectionTypes";
import LanguageOffIcon from "../../../../../icons/LanguageOffIcon";
import ActionsMenuButton from "../../../../common/ActionsMenuButton";
import DataSubmissionStatusTag from "../../data-submissions/DataSubmissionStatusTag";
import { useDataRequestPageContext } from "./DataRequestPageContext";

const ActionsCell = ({ row }: { row: RequestSubmissionInfo }) => {
  const {
    hasPermissionsToManage,
    onPublishSubmissions,
    onUnpublishSubmissions,
    onRequestChangesForSubmissions,
    onDeleteSubmissions,
  } = useDataRequestPageContext();

  if (!hasPermissionsToManage) {
    return null;
  }

  if (row.status === "Draft") {
    return (
      <ActionsMenuButton
        items={[
          {
            icon: <LanguageButton color="action" />,
            label: "Publish",
            onClick: () => onPublishSubmissions([row]),
          },
          {
            icon: <DeleteIcon color="error" />,
            label: "Delete",
            onClick: () => onDeleteSubmissions([row]),
          },
        ]}
      ></ActionsMenuButton>
    );
  }

  if (row.status === "Pending" || row.status === "ChangesRequested") {
    return (
      <IconButton onClick={() => onUnpublishSubmissions([row])}>
        <LanguageOffIcon />
      </IconButton>
    );
  }

  if (row.status === "Completed") {
    return (
      <IconButton onClick={() => onRequestChangesForSubmissions([row])}>
        <RevertIcon color="warning" />
      </IconButton>
    );
  }

  return null;
};

export const columnDefinitions: GridColDef<RequestSubmissionInfo>[] = [
  {
    field: "recipientName",
    headerName: "Portfolio Company",
    cellClassName: "highlighted-action",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row }) => <DataSubmissionStatusTag status={row.status} />,
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    flex: 0.5,
    minWidth: 112,
    valueFormatter: (value: string) => convertISODate(value),
  },
  {
    field: "recipientContacts",
    headerName: "Contacts",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row }) => {
      if (row.recipientContacts.length === 0) {
        return "-";
      }

      return (
        <InlineItemsList<RequestSubmissionContact>
          displayCount={1}
          items={distinctBy(row.recipientContacts, (c) => c.contactName)}
          propGetter={(c) => c.contactName}
          justify="flex-start"
        />
      );
    },
  },
  {
    field: "submittedBy",
    headerName: "Submitted by",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "submittedAt",
    headerName: "Submitted at",
    flex: 0.5,
    minWidth: 160,
    valueFormatter: (value: string | undefined) => convertISODateTime(value),
  },
  {
    field: "actions",
    headerName: "",
    resizable: false,
    width: 80,
    cellClassName: "grid-row-actions",
    renderCell: ({ row }) => <ActionsCell row={row} />,
  },
];
