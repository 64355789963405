import { GridCellModes, GridCellModesModel, GridCellParams, GridGroupNode, GridRowId } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useCallback, useEffect, useState } from "react";

export const useGridGroupsCollapsed = (apiRef: React.MutableRefObject<GridApiPremium>) => {
  const [collapsedGroupIds, setCollapsedGroupIds] = useState<GridRowId[]>([]);

  const isGroupExpanded = useCallback(
    (groupNode: GridGroupNode) => !collapsedGroupIds.includes(groupNode.id),
    [collapsedGroupIds]
  );

  useEffect(() => {
    return apiRef.current.subscribeEvent("rowExpansionChange", ({ id, childrenExpanded }) => {
      setCollapsedGroupIds((ids) => (childrenExpanded ? ids.filter((nodeId) => nodeId !== id) : [...ids, id]));
    });
  }, [apiRef]);

  return { isGroupExpanded };
};

export const useGridGroupsExpanded = (apiRef: React.MutableRefObject<GridApiPremium>) => {
  const [expandedGroupIds, setExpandedGroupIds] = useState<GridRowId[]>([]);

  const isGroupExpanded = useCallback(
    (groupNode: GridGroupNode) => expandedGroupIds.includes(groupNode.id),
    [expandedGroupIds]
  );

  useEffect(() => {
    return apiRef.current.subscribeEvent("rowExpansionChange", ({ id, childrenExpanded }) => {
      setExpandedGroupIds((ids) => (childrenExpanded ? [...ids, id] : ids.filter((nodeId) => nodeId !== id)));
    });
  }, [apiRef]);

  return { isGroupExpanded };
};

// https://mui.com/x/react-data-grid/recipes-editing/

export const useSingleClickEditing = () => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    const targetElement = event.target as Element;
    if (targetElement.nodeType === 1 && !event.currentTarget.contains(targetElement)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id] ?? {}).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });

    setTimeout(() => {
      const input = targetElement.querySelector("input");
      if (input) {
        input.select();
      }
    });
  }, []);

  return { cellModesModel, setCellModesModel, handleCellClick };
};
