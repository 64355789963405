import { GridColDef } from "@mui/x-data-grid-premium";
import { convertISODate, convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { DataCollectionSubmissionInfo } from "../../../../api/types/dataCollectionTypes";
import DataSubmissionStatusTag from "./DataSubmissionStatusTag";

export const columnDefinitions: GridColDef<DataCollectionSubmissionInfo>[] = [
  {
    field: "recipientName",
    headerName: "Portfolio Company",
    cellClassName: "highlighted-action",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "requestTemplateName",
    headerName: "Template",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "reportingDate",
    headerName: "Reporting Date",
    flex: 0.5,
    minWidth: 112,
    valueFormatter: (value: string | undefined) => convertISODate(value),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    minWidth: 160,
    renderCell: ({ row }) => <DataSubmissionStatusTag status={row.status} />,
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    flex: 0.5,
    minWidth: 112,
    valueFormatter: (value: string) => convertISODate(value),
  },
  {
    field: "submittedBy",
    headerName: "Submitted by",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "submittedAt",
    headerName: "Submitted at",
    flex: 0.5,
    width: 160,
    valueFormatter: (value: string | undefined) => convertISODateTime(value),
  },
];
