import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Button, Menu, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import FileAccessIcon from "../../../../../../icons/FileAccessIcon";
import CategoryTag from "../../../../../common/CategoryTag";
import CustomDataGridHeaderToolbar from "../../../../../common/grid/CustomDataGridHeaderToolbar";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import {
  deleteDocumentsAction,
  isChangeAccessOptionsAllowed,
  selectAccessOptions,
  updateDocumentsAccessOptionsAction,
  updateDocumentsCategoryAction,
} from "./fundraisingDocumentsState";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  disabled?: boolean;
}

const FundraisingDocumentsGridToolbar = ({ selectedIds, setSelectedIds, disabled }: Props) => {
  const { fundraisingCategories, isContentEditable } = useFundraisingDetailsPageContext();
  const { documentsState, setDocumentsState, onDownloadFile, onDownloadMultipleFiles } =
    useFundraisingDocumentsContext();

  const [isCategorySelectorOpen, setCategorySelectorOpen] = useState(false);
  const changeCategoryButtonRef = useRef<HTMLButtonElement>(null);

  const [isFileAccessSelectorOpen, setFileAccessSelectorOpen] = useState(false);
  const changeFileAccessButtonRef = useRef<HTMLButtonElement>(null);

  const handleDownloadClick = () => {
    selectedIds.length === 1 ? onDownloadFile(defined(selectedIds[0])) : onDownloadMultipleFiles(selectedIds);
  };

  const selectedUploadingDocuments = documentsState.documents.filter(
    (doc) => selectedIds.includes(doc.fileId) && doc.uploadStatus === "uploading"
  );

  const selectedDocumentsWithChangeableAccess = documentsState.documents.filter(
    (doc) => selectedIds.includes(doc.fileId) && isChangeAccessOptionsAllowed(doc)
  );

  return (
    <CustomDataGridHeaderToolbar sx={{ height: "3.25rem" }} selection={[selectedIds, setSelectedIds]}>
      {isContentEditable && (
        <Button
          ref={changeCategoryButtonRef}
          disabled={disabled}
          onClick={() => setCategorySelectorOpen(true)}
          color="secondary"
          variant="text"
          startIcon={<EditIcon />}
        >
          Change Category
        </Button>
      )}

      {isContentEditable && (
        <Button
          ref={changeFileAccessButtonRef}
          disabled={disabled || selectedDocumentsWithChangeableAccess.length === 0}
          onClick={() => setFileAccessSelectorOpen(true)}
          color="secondary"
          variant="text"
          startIcon={<FileAccessIcon />}
        >
          File Access
        </Button>
      )}

      <Button
        disabled={disabled || selectedUploadingDocuments.length > 0}
        onClick={handleDownloadClick}
        color="secondary"
        variant="text"
        startIcon={<DownloadIcon />}
      >
        Download
      </Button>

      {isContentEditable && (
        <Button
          disabled={disabled}
          onClick={() => setDocumentsState(deleteDocumentsAction(selectedIds))}
          color="error"
          variant="text"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      )}

      <Menu
        open={isCategorySelectorOpen}
        onClose={() => setCategorySelectorOpen(false)}
        anchorEl={changeCategoryButtonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {fundraisingCategories.map((category) => (
          <MenuItem
            key={category.externalId}
            value={category.externalId}
            dense
            onClick={() =>
              setDocumentsState(
                updateDocumentsCategoryAction(selectedIds, fundraisingCategories, category.externalId, category.name)
              )
            }
          >
            <CategoryTag categoryName={category.name} />
          </MenuItem>
        ))}
      </Menu>

      <Menu
        open={isFileAccessSelectorOpen}
        onClose={() => setFileAccessSelectorOpen(false)}
        anchorEl={changeFileAccessButtonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {selectAccessOptions.map(([value, options]) => (
          <MenuItem
            key={value}
            value={value}
            dense
            onClick={() =>
              setDocumentsState(
                updateDocumentsAccessOptionsAction(
                  selectedDocumentsWithChangeableAccess.map((d) => d.fileId),
                  options,
                  fundraisingCategories
                )
              )
            }
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </CustomDataGridHeaderToolbar>
  );
};

export default FundraisingDocumentsGridToolbar;
