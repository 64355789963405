import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { UserConsentInfo } from "../../../../api/types/userConsentTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import RecordCounter from "../../../common/filters/RecordCounter";
import CreateUserConsentDialog from "./CreateUserConsentDialog";
import DeleteUserConsentDialog from "./DeleteUserConsentDialog";
import { columnDefinitions } from "./userConsentsGridDataProvider";
import { UserConsentsSectionContextProvider } from "./UserConsentsSectionContext";

interface DialogState {
  openDialog?: "create" | "delete";
  consentNameToDelete?: string;
}

const UserConsentsSection = () => {
  const { clientCode, hasPermissions } = useClientContext();
  const navigate = useNavigate();

  const [categories, fetchCategoriesError] = useFetch(adminApi.getAccessCategories);
  const [consents, fetchConsentsError, { setData: setConsents }] = useFetch(adminApi.getUserConsents);

  const [dialogState, setDialogState] = useState<DialogState>({});

  const fetchError = fetchConsentsError || fetchCategoriesError;
  if (fetchError) {
    logError(fetchError, "[UserConsentsSection] fetchError");
    return <DataLoadingFailed />;
  }

  const hasEditPermissions = hasPermissions(["ManageInvestorPortalSettings"]);

  const loading = !consents || !categories;

  const navigateToConsent = (name: string) => {
    navigate(`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.userConsents}/${name}`);
  };

  const handleCreateConsent = () => {
    setDialogState({ openDialog: "create" });
  };

  const handleConsentCreated = (consentName: string) => {
    setDialogState({});
    navigateToConsent(consentName);
  };

  const handleDeleteConsent = (consentNameToDelete: string) => {
    setDialogState({ openDialog: "delete", consentNameToDelete });
  };

  const handleConsentDeleted = (consentName: string) => {
    setDialogState({});
    setConsents((prev) => prev?.filter((consent) => consent.name !== consentName));
  };

  return (
    <UserConsentsSectionContextProvider
      categories={(categories ?? []).sort((a, b) => a.name.localeCompare(b.name))}
      hasEditPermissions={hasEditPermissions}
      onDeleteConsent={handleDeleteConsent}
    >
      <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box pt={0.5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
          <RecordCounter records={consents?.length ?? 0} total={consents?.length ?? 0} hide={loading} />
          {hasEditPermissions && (
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreateConsent}>
              Add New
            </Button>
          )}
        </Box>

        <DataGrid<UserConsentInfo>
          columns={columnDefinitions}
          rows={consents ?? []}
          getRowId={(row) => row.name}
          loading={loading}
          rowHeight={40}
          columnHeaderHeight={36}
          selectableRows
          onRowClick={({ row }) => navigateToConsent(row.name)}
          slots={{
            noRowsOverlay: () => (
              <CenteredWrapper>
                <Typography color="text.secondary">No user consents yet</Typography>
              </CenteredWrapper>
            ),
          }}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableColumnSelector
          disableColumnSorting
          disableRowSelectionOnClick
        />
      </Container>

      {dialogState.openDialog === "create" && (
        <CreateUserConsentDialog
          onClose={() => setDialogState({})}
          onCreated={handleConsentCreated}
          existingConsentNames={consents?.map((c) => c.name) ?? []}
        />
      )}

      {dialogState.openDialog === "delete" && dialogState.consentNameToDelete && (
        <DeleteUserConsentDialog
          consentName={dialogState.consentNameToDelete}
          onClose={() => setDialogState({})}
          onDeleted={handleConsentDeleted}
        />
      )}
    </UserConsentsSectionContextProvider>
  );
};

export default UserConsentsSection;
