import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import RecordCounter from "../../../common/filters/RecordCounter";
import { useDataCollectionsPageContext } from "../DataCollectionsPageContext";
import { dataCollectionsNavigationViewsCaptionMap } from "../dataCollectionsPageTypes";
import DataRequestTemplatesGrid from "./DataRequestTemplatesGrid";

const DataRequestTemplatesList = () => {
  const { templates } = useDataCollectionsPageContext();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearch = (value: string) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const allTemplates = templates ?? [];

  const filteredTemplates = searchTerm
    ? allTemplates.filter((t) => t.name.toLowerCase().includes(searchTerm))
    : allTemplates;

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Box py={2} px={3} borderBottom={1} borderColor="divider">
        <Typography variant="h6">{dataCollectionsNavigationViewsCaptionMap["data_request_templates"]}</Typography>
      </Box>
      <Stack width="100%" spacing={2} pt={2.5} px={3} flex={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <RecordCounter records={filteredTemplates.length} total={allTemplates.length} />
          <SearchField initialValue={searchTerm} debounceTimeMs={300} onSearch={handleSearch} />
        </Box>
        <DataRequestTemplatesGrid rows={filteredTemplates} />
      </Stack>
    </Box>
  );
};

export default DataRequestTemplatesList;
