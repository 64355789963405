import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import { FormCellValue, FormRowExtensionRename } from "../../../../../api/types/dataCollectionTypes";
import { DataSubmissionBlockContent } from "../../../../../api/types/inputFormTypes";

interface Props {
  hasPermissionsToEdit: boolean;
  getBlockContent: (blockId: string) => DataSubmissionBlockContent | undefined;
  onBlockCellValueEdit: (blockId: string, formCellValue: FormCellValue) => void;
  onBlockRowExtensionRename: (blockId: string, formRowExtensionRename: FormRowExtensionRename) => void;
}

const DataSubmissionFormContext = createContext<Props | undefined>(undefined);

export const DataSubmissionFormContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <DataSubmissionFormContext.Provider value={contextValue}>{children}</DataSubmissionFormContext.Provider>
);

export const useDataSubmissionFormContext = () =>
  defined(useContext(DataSubmissionFormContext), "Attempt to use DataSubmissionFormContext without provider");
