import {
  addDays,
  addMonths,
  addQuarters,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  getQuarter,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";

export type DescriptiveDate = {
  title: string;
  description?: string;
  from?: string;
  to?: string;
};

const forAnytime = () => ({
  from: undefined,
  to: undefined,
});

const forToday = () => {
  const date = Date.now();
  return {
    description: format(date, "dd MMM yyyy"),
    from: startOfDay(date).toISOString(),
    to: endOfDay(date).toISOString(),
  };
};

const forYesterday = () => {
  const date = subDays(Date.now(), 1);
  return {
    description: format(date, "dd MMM yyyy"),
    from: startOfDay(date).toISOString(),
    to: endOfDay(date).toISOString(),
  };
};

const forThisQuarter = () => {
  const date = Date.now();
  const startOfTheQuarter = startOfQuarter(date);
  const endOfTheQuarter = endOfQuarter(date);

  return {
    description: `Q${getQuarter(date)} ${format(date, "yyyy")}`,
    from: startOfTheQuarter.toISOString(),
    to: endOfTheQuarter.toISOString(),
  };
};

const forThisMonth = () => {
  const date = new Date();
  const startOfTheMonth = startOfMonth(date);
  const endOfTheMonth = endOfMonth(date);

  return {
    description: format(date, "MMM yyyy"),
    from: startOfTheMonth.toISOString(),
    to: endOfTheMonth.toISOString(),
  };
};

const forThisYear = () => {
  const date = new Date();
  const startOfTheYear = startOfYear(date);

  return {
    description: format(date, "yyyy"),
    from: startOfTheYear.toISOString(),
    to: endOfYear(date).toISOString(),
  };
};

const forLastDays = (daysCount: number) => {
  const date = new Date();
  const shiftedDate = endOfDay(subDays(date, daysCount));

  return {
    description: `${format(shiftedDate, "MMM dd")} - ${format(date, "MMM dd")}`,
    from: shiftedDate.toISOString(),
    to: undefined,
  };
};

function forLastMonths(monthsCount: number) {
  const date = new Date();
  const fromDate = startOfMonth(subMonths(date, monthsCount));
  const toDate = endOfMonth(date);

  return {
    description: `${format(fromDate, "MMM yyyy")} - ${format(date, "MMM yyyy")}`,
    from: fromDate.toISOString(),
    to: toDate.toISOString(),
  };
}

function forLastMonth() {
  const date = subMonths(Date.now(), 1);
  const startOfTheMonth = startOfMonth(date);
  const endOfTheMonth = endOfMonth(date);
  return {
    description: format(startOfTheMonth, "MMM yyyy"),
    from: startOfTheMonth.toISOString(),
    to: endOfTheMonth.toISOString(),
  };
}

const forLastQuarter = () => {
  const date = subQuarters(Date.now(), 1);
  const startOfTheQuarter = startOfQuarter(date);
  const endOfTheQuarter = endOfQuarter(date);
  return {
    description: `Q${getQuarter(date)} ${format(date, "yyyy")}`,
    from: startOfTheQuarter.toISOString(),
    to: endOfTheQuarter.toISOString(),
  };
};

const forLastYear = () => {
  const date = subYears(Date.now(), 1);
  const startOfTheYear = startOfYear(date);
  const endOfTheYear = endOfYear(date);
  return {
    description: format(startOfTheYear, "yyyy"),
    from: startOfTheYear.toISOString(),
    to: endOfTheYear.toISOString(),
  };
};

const forNextYear = () => {
  const date = addYears(Date.now(), 1);
  const startOfTheYear = startOfYear(date);
  const endOfTheYear = endOfYear(date);
  return {
    description: format(startOfTheYear, "yyyy"),
    from: startOfTheYear.toISOString(),
    to: endOfTheYear.toISOString(),
  };
};

const forNextDays = (daysCount: number) => {
  const date = new Date();
  const shiftedDate = endOfDay(addDays(date, daysCount));
  return {
    description: `${format(date, "MMM dd")} - ${format(shiftedDate, "MMM dd")}`,
    from: date.toISOString(),
    to: shiftedDate.toISOString(),
  };
};

const forNextMonth = () => {
  const date = addMonths(Date.now(), 1);
  const startOfTheMonth = startOfMonth(date);
  const endOfTheMonth = endOfMonth(date);
  return {
    description: format(startOfTheMonth, "MMM yyyy"),
    from: startOfTheMonth.toISOString(),
    to: endOfTheMonth.toISOString(),
  };
};

const forNextQuarter = () => {
  const date = addQuarters(Date.now(), 1);
  const startOfTheQuarter = startOfQuarter(date);
  const endOfTheQuarter = endOfQuarter(date);
  return {
    description: `Q${getQuarter(date)} ${format(date, "yyyy")}`,
    from: startOfTheQuarter.toISOString(),
    to: endOfTheQuarter.toISOString(),
  };
};

export const anytime: DescriptiveDate = { title: "Any time", ...forAnytime() };

export const today: DescriptiveDate = { title: "Today", ...forToday() };
export const yesterday: DescriptiveDate = { title: "Yesterday", ...forYesterday() };

export const thisMonth: DescriptiveDate = { title: "This month", ...forThisMonth() };
export const thisQuarter: DescriptiveDate = { title: "This quarter", ...forThisQuarter() };
export const thisYear: DescriptiveDate = { title: "This year", ...forThisYear() };

export const lastSevenDays: DescriptiveDate = { title: "Last 7 Days", ...forLastDays(7) };
export const last30Days: DescriptiveDate = { title: "Last 30 Days", ...forLastDays(30) };
export const last12Months: DescriptiveDate = { title: "Last 12 Months", ...forLastMonths(12) };

export const lastMonth: DescriptiveDate = { title: "Last month", ...forLastMonth() };
export const lastQuarter: DescriptiveDate = { title: "Last quarter", ...forLastQuarter() };
export const lastYear: DescriptiveDate = { title: "Last year", ...forLastYear() };

export const nextSevenDays: DescriptiveDate = { title: "Next 7 Days", ...forNextDays(7) };
export const next30Days: DescriptiveDate = { title: "Next 30 Days", ...forNextDays(30) };

export const nextMonth: DescriptiveDate = { title: "Next month", ...forNextMonth() };
export const nextQuarter: DescriptiveDate = { title: "Next quarter", ...forNextQuarter() };
export const nextYear: DescriptiveDate = { title: "Next year", ...forNextYear() };

export const getFilterDateItems = () => [
  anytime,
  today,
  lastSevenDays,
  last30Days,
  thisMonth,
  thisQuarter,
  thisYear,
  lastMonth,
  lastQuarter,
  lastYear,
];
