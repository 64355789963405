import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import { parseSearchParamWithFallback } from "../../../../shared/utilities/searchParamsHelper";
import adminApi from "../../../api/adminApi";
import { useClientContext } from "../../../context/ClientContext";
import DataRequestTemplatesList from "./data-request-templates/DataRequestTemplatesList";
import DataRequestsList from "./data-requests/DataRequestsList";
import DataSubmissionsList from "./data-submissions/DataSubmissionsList";
import DataCollectionsNavigation from "./DataCollectionsNavigation";
import { DataCollectionsPageContextProvider } from "./DataCollectionsPageContext";
import { DataCollectionsNavigationView, dataCollectionsNavigationViews } from "./dataCollectionsPageTypes";

const DataCollectionsPage = () => {
  const { hasPermissions } = useClientContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [templates, fetchError] = useFetch(adminApi.getDataCollectionRequestTemplates);

  const [selectedView, setSelectedView] = useState<DataCollectionsNavigationView>(
    parseSearchParamWithFallback(searchParams, "view", dataCollectionsNavigationViews)
  );

  if (fetchError) {
    logError(fetchError, "[DataCollectionsPage] getDataCollectionRequestTemplates");
    return <DataLoadingFailed title="Could not load data collection templates" />;
  }

  if (templates === undefined) {
    return <InlineLoader />;
  }

  const handleViewChange = (view: DataCollectionsNavigationView) => {
    setSelectedView(view);
    setSearchParams({ view });
  };

  const hasPermissionsToManage = hasPermissions(["ManagePortfolioMonitoring"]);

  return (
    <DataCollectionsPageContextProvider hasPermissionsToManage={hasPermissionsToManage} templates={templates}>
      <Box display="flex" overflow="hidden" height="100%">
        <DataCollectionsNavigation selectedView={selectedView} onSelectedViewChange={handleViewChange} />
        <Divider flexItem orientation="vertical" />
        {selectedView === "data_submissions" && <DataSubmissionsList />}
        {selectedView === "data_collection_requests" && <DataRequestsList />}
        {selectedView === "data_request_templates" && <DataRequestTemplatesList />}
      </Box>
    </DataCollectionsPageContextProvider>
  );
};

export default DataCollectionsPage;
