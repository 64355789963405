import StatusChip from "../../../../../shared/components/StatusChip";
import { DataCollectionSubmissionStatus } from "../../../../api/types/dataCollectionTypes";
import { dataCollectionSubmissionStatusesCaptionsMap } from "../../../../utilities/enumCaptions";

interface Props {
  status: DataCollectionSubmissionStatus;
}

const DataSubmissionStatusTag = ({ status }: Props) => {
  switch (status) {
    case "Draft":
      return (
        <StatusChip
          label={dataCollectionSubmissionStatusesCaptionsMap["Draft"]}
          withDot
          color={(t) => t.palette.text.secondary}
        />
      );
    case "Pending":
      return (
        <StatusChip
          label={dataCollectionSubmissionStatusesCaptionsMap["Pending"]}
          withDot
          color={(t) => t.palette.text.secondary}
        />
      );
    case "Completed":
      return (
        <StatusChip
          label={dataCollectionSubmissionStatusesCaptionsMap["Completed"]}
          withDot
          color={(t) => t.palette.info.main}
        />
      );
    case "ChangesRequested":
      return (
        <StatusChip
          label={dataCollectionSubmissionStatusesCaptionsMap["ChangesRequested"]}
          withDot
          color={(t) => t.palette.warning.main}
        />
      );
    default:
      return null;
  }
};

export default DataSubmissionStatusTag;
