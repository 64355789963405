import { Box, Stack, Typography } from "@mui/material";
import objectHash from "object-hash";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import useToggleState from "../../../../../shared/hooks/useToggleState";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataCollectionSubmissionInfo } from "../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../context/ClientContext";
import {
  firstPageAction,
  getInitialPaginatedItemsState,
  getPagingParams,
  loadItemsAction,
  nextPageAction,
} from "../../../../state/paginatedState";
import storage from "../../../../storage/storage";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { createReducer, getInitialState } from "../../../common/filters/filterState";
import { emptySearchFilterDefinition } from "../../../common/filters/handlers/filterHandlers";
import { useDataCollectionsPageContext } from "../DataCollectionsPageContext";
import { dataCollectionsNavigationViewsCaptionMap } from "../dataCollectionsPageTypes";
import DataSubmissionsGrid from "./DataSubmissionsGrid";
import { createSelectOptionsResolver, filterDefinitions, getFilterRequestPayload } from "./dataSubmissionsGridFilters";

const DataSubmissionsList = () => {
  const { clientCode } = useClientContext();
  const { templates } = useDataCollectionsPageContext();

  const [pageState, setPageState] = useState(getInitialPaginatedItemsState<DataCollectionSubmissionInfo>());

  const [filterState, dispatchFilters] = useReducer(
    createReducer<DataCollectionSubmissionInfo>(),
    getInitialState(`${clientCode}_data_submissions_filter_v1`, filterDefinitions, emptySearchFilterDefinition(), [])
  );

  const [updateTrigger, toggleUpdateTrigger] = useToggleState(false);

  const resetPageOnFiltersChange = useCallback(() => {
    if (pageState.page !== 0) {
      setPageState(firstPageAction());
    } else {
      toggleUpdateTrigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectHash(getFilterRequestPayload(filterState))]);

  useEffect(() => {
    resetPageOnFiltersChange();
  }, [resetPageOnFiltersChange]);

  const searchDataSubmissions = useCallback(
    () =>
      adminApi.searchDataSubmissions({
        paging: getPagingParams(pageState.page),
        ...getFilterRequestPayload(filterState),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageState.page, updateTrigger]
  );

  const [, fetchError, { isFetching, fetch: fetchDataSubmissions }] = useFetch(searchDataSubmissions, (resp) => {
    setPageState(loadItemsAction(resp));
  });

  const handleRowsScrollEnd = useCallback(() => {
    setTimeout(() => setPageState(nextPageAction()), 100);
  }, []);

  const getSelectOptions = useMemo(() => createSelectOptionsResolver(templates), [templates]);

  if (fetchError) {
    logError(fetchError, "[DataSubmissionsList] searchDataSubmissions");
    storage.clearFilterState(`${clientCode}_data_submissions_filter_v1`);
    return <DataLoadingFailed title="Could not load data submissions" />;
  }

  const handleRefresh = () => {
    if (pageState.page === 0) {
      fetchDataSubmissions();
    } else {
      setPageState(firstPageAction());
    }
  };

  return (
    <FilterContextProvider
      filterState={filterState}
      dispatchFilters={dispatchFilters}
      getSelectOptions={getSelectOptions}
    >
      <Box flex={1} display="flex" flexDirection="column">
        <Box py={2} px={3} borderBottom={1} borderColor="divider">
          <Typography variant="h6">{dataCollectionsNavigationViewsCaptionMap["data_submissions"]}</Typography>
        </Box>

        <Stack width="100%" spacing={2} pt={2.5} px={3} flex={1}>
          <FiltersPanel
            totalCount={pageState.totalRecords}
            recordCount={pageState.items.length}
            isLoading={isFetching}
            onRefresh={handleRefresh}
          />

          <DataSubmissionsGrid rows={pageState.items} isLoading={isFetching} onRowsScrollEnd={handleRowsScrollEnd} />
        </Stack>
      </Box>
    </FilterContextProvider>
  );
};

export default DataSubmissionsList;
