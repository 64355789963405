import { EntityOptionFieldType } from "./objectTypes";

export interface Metric {
  id: string;
  entryNo: string;
  dataType: MetricDataType;
  aggregationType: MetricAggregationType;
  inactive: boolean;
  name: string;
  description?: string;
  category?: string;
  valueSource?: MetricValueSourceConfiguration;
  defaultFormat?: MetricFormatConfiguration;
  extensionConfiguration?: MetricExtensionConfiguration;
  createdAt: string;
  createdBy: string;
  createdByUserName?: string;
  updateAt: string;
  updatedBy: string;
  updatedByUserName?: string;
  isExtended: boolean;
}

export const allMetricDataTypes = ["Text", "Number", "Money", "Date", "Select"] as const;
export type MetricDataType = (typeof allMetricDataTypes)[number];

export const allMetricAggegationTypes = ["EndOfPeriod", "Sum"] as const;
export type MetricAggregationType = (typeof allMetricAggegationTypes)[number];

export interface MetricFormatConfiguration {
  numberDisplayStyle?: NumberDisplayStyle;
  numberOfDecimals?: number;
}

export interface MetricExtensionConfiguration {
  extensionName: string;
  globalDictionaryName?: EntityOptionFieldType;
  allowedValues?: string[];
}

export interface MetricValueSourceConfiguration {
  globalDictionaryName?: EntityOptionFieldType;
  allowedValues?: string[];
}

export const allNumberDisplayStyles = ["Percentage", "Thousands", "Millions", "Billions", "Multiple"] as const;
export type NumberDisplayStyle = (typeof allNumberDisplayStyles)[number];

export interface CreateMetricRequest {
  dataType: MetricDataType;
  aggregationType: MetricAggregationType;
  inactive?: boolean;
  name: string;
  description?: string;
  category?: string;
  valueSource?: MetricValueSourceConfiguration;
  defaultFormat?: MetricFormatConfiguration;
  extensionConfiguration?: MetricExtensionConfiguration;
}

export interface UpdateMetricRequest {
  aggregationType?: MetricAggregationType;
  inactive?: boolean;
  name?: string;
  description?: string;
  category?: string;
  valueSource?: MetricValueSourceConfiguration;
  defaultFormat?: MetricFormatConfiguration;
  extensionConfiguration?: MetricExtensionConfiguration;
}
