import { Stack, Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import CenteredWrapper from "../../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { DocumentViews } from "../../../../../api/types/documentActivityTypes";
import DocumentViewsGridRowDetail from "../../../investor-portal/document-activity/DocumentViewsGridRowDetail";
import { columnDefinitions, isDetailPanelAvailable } from "./userDocumentActivityGridDataProvider";

interface Props {
  documentViews: DocumentViews[];
  loading: boolean;
  excludeColumns?: string[];
}

const UserDocumentActivitySection = ({ documentViews, loading, excludeColumns }: Props) => {
  const columnVisibilityModel = Object.fromEntries((excludeColumns ?? []).map((fieldId) => [fieldId, false]));

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams<DocumentViews>) =>
      isDetailPanelAvailable(row) ? <DocumentViewsGridRowDetail row={row} /> : undefined,
    []
  );

  const getDetailPanelHeight = useCallback(() => 256, []);

  return (
    <Stack spacing={2} height="100%">
      <Typography variant="subtitle2" pt={1}>
        All Activity
      </Typography>
      <DataGrid<DocumentViews>
        rows={documentViews}
        columns={columnDefinitions}
        columnHeaderHeight={36}
        loading={loading}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        slots={{
          loadingOverlay: () => <InlineLoader />,
          noRowsOverlay: () => (
            <CenteredWrapper>
              <Typography color="text.secondary">No document activity</Typography>
            </CenteredWrapper>
          ),
        }}
        columnVisibilityModel={columnVisibilityModel}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnSorting
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
      />
    </Stack>
  );
};

export default UserDocumentActivitySection;
