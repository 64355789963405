import { grey } from "@mui/material/colors";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridEventListener,
} from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useState } from "react";
import DataGrid from "../../../../../../../shared/components/grid/DataGrid";
import { generateGuid } from "../../../../../../../shared/utilities/generateGuid";
import { TableBlockColumn, TableBlockRow } from "../../../../../../api/types/inputFormTypes";
import { useSingleClickEditing } from "../../../../../common/grid/gridHooks";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import { getColumns, getRows, RowModel } from "./monthlyFinancialsInputGridDataProvider";
import { PortfolioMonitoringInputTableContextProvider } from "./PortfolioMonitoringInputTableContext";
import { calculateTotals } from "./tableTotalsCalculator";

interface Props {
  blockId: string;
  columnDefinitions: TableBlockColumn[];
  rowDefinitions: TableBlockRow[];
}

const MonthlyFinancialsInputTable = ({ blockId, columnDefinitions, rowDefinitions }: Props) => {
  const { hasPermissionsToEdit, onBlockCellValueEdit, onBlockRowExtensionRename } = useDataSubmissionFormContext();

  const [rows, setRows] = useState<RowModel[]>(getRows(rowDefinitions));

  const columns = useMemo(() => getColumns(columnDefinitions), [columnDefinitions]);

  const { cellModesModel, setCellModesModel, handleCellClick } = useSingleClickEditing();

  const isCellEditable = useCallback(
    ({ row, colDef }: { row: RowModel; colDef: GridColDef<RowModel> }) =>
      hasPermissionsToEdit && row.type === "Metric" && (colDef.field !== "name" || row.isNewExtensionRow === true),
    [hasPermissionsToEdit]
  );

  const handleCellModesModelChange = (newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  };

  const handleRowUpdate = (newRow: RowModel, oldRow: RowModel) => {
    if (newRow.type !== "Metric" || oldRow.type !== "Metric") {
      return newRow;
    }

    const updatedRow = { ...newRow };

    const updatedValueEntry = Object.entries(updatedRow.values).find(
      ([columnId, currentValue]) => oldRow.values[columnId] !== currentValue
    );

    const hasNameChanged = oldRow.name !== updatedRow.name;

    const validationError = !hasNameChanged
      ? undefined
      : !updatedRow.name
        ? "Required value"
        : rows.some(
              (r) =>
                r.type === "Metric" &&
                r.metricId === updatedRow.metricId &&
                r.id !== updatedRow.id &&
                r.name === updatedRow.name
            )
          ? "Extension must be unique"
          : undefined;

    const rowId = updatedRow.isMetricExtensionRow ? updatedRow.metricId : updatedRow.id;

    if (updatedValueEntry !== undefined) {
      const [columnId, value] = updatedValueEntry;
      const rowExtensionValue = updatedRow.isMetricExtensionRow ? updatedRow.name : undefined;

      onBlockCellValueEdit(blockId, {
        rowId,
        rowExtensionValue,
        columnId,
        value,
      });
    }

    if (hasNameChanged && !validationError) {
      onBlockRowExtensionRename(blockId, {
        rowId,
        oldRowExtensionValue: oldRow.name,
        newRowExtensionValue: updatedRow.name,
      });
    }

    setTimeout(
      () =>
        setRows((prevRows) => {
          const newRows = prevRows.map((row) => {
            if (row.id !== updatedRow.id) {
              return row;
            }

            return { ...updatedRow, validationError };
          });

          return calculateTotals(newRows);
        }),
      100
    );

    return updatedRow;
  };

  const handleAddMetricExtension = (rowId: string) => {
    setRows((prevRows) =>
      prevRows.reduce<RowModel[]>((result, row) => {
        if (row.id !== rowId) {
          return [...result, row];
        }

        const newExtensionRow: RowModel = {
          isNewExtensionRow: true,
          validationError: "Required value",
          type: "Metric",
          id: `${row.id}_${generateGuid()}`,
          indentationLevel: row.indentationLevel + 1,
          metricId: row.id,
          isMetricExtensionRow: true,
          values: {},
          previousValues: {},
          name: "",
          dataType: "Money", // TODO: Get from metric
          aggregationType: "Sum",
        };

        return [...result, row, newExtensionRow];
      }, [])
    );
  };

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = (params: GridCellParams<RowModel>, event) => {
    if (event.code !== "Tab") {
      return;
    }

    event.defaultMuiPrevented = true;
    event.preventDefault();

    const rowId = params.row.id;
    const colId = params.colDef.field;

    let nextEditableRow: RowModel | undefined;

    if (event.shiftKey) {
      const prevRowIdIndex = rows.findIndex((row) => row.id === rowId) - 1;
      nextEditableRow = [...rows.slice(0, prevRowIdIndex + 1)]
        .reverse()
        .find((row) => isCellEditable({ row, colDef: params.colDef }));
    } else {
      const nextRowIdIndex = rows.findIndex((row) => row.id === rowId) + 1;
      nextEditableRow = rows.slice(nextRowIdIndex).find((row) => isCellEditable({ row, colDef: params.colDef }));
    }

    if (!nextEditableRow) {
      return;
    }

    // Move Edit state to the next editable row
    setCellModesModel((prevModel) => ({
      ...prevModel,
      [rowId]: {
        ...prevModel[rowId],
        [colId]: { mode: GridCellModes.View },
      },
      [nextEditableRow.id]: {
        [colId]: { mode: GridCellModes.Edit },
      },
    }));

    setTimeout(() => {
      // Find the cell element from the next row and column
      const nextRowElement = document.querySelector(`[data-id="${nextEditableRow.id}"]`);
      if (nextRowElement) {
        const input = nextRowElement.querySelector("input");
        if (input) {
          input.select();
        }
      }
    }, 100);
  };

  return (
    <PortfolioMonitoringInputTableContextProvider onAddMetricExtension={handleAddMetricExtension}>
      <DataGrid<RowModel>
        columns={columns}
        rows={rows}
        rowHeight={40}
        pinnedColumns={{ left: ["name"] }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        onCellKeyDown={handleCellKeyDown}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
        disableColumnSorting
        showCellVerticalBorder
        showColumnVerticalBorder
        editMode="cell"
        isCellEditable={isCellEditable}
        processRowUpdate={handleRowUpdate}
        noScrollableContainer
        sx={(t) => ({
          borderColor: t.palette.divider,
          ".MuiDataGrid-columnHeaders": {
            borderTop: `1px solid ${t.palette.divider}`,
            ".MuiDataGrid-columnHeader": {
              ":first-of-type": {
                borderLeft: `1px solid ${t.palette.divider}`,
              },
              "&.MuiDataGrid-columnHeader--last": {
                borderRight: `1px solid ${t.palette.divider}`,
              },
              "&.readonly-header": {
                backgroundColor: grey[50],
              },
            },
          },
          ".MuiDataGrid-columnHeaderTitleContainerContent": {
            width: "100%",
          },
          ".MuiDataGrid-row": {
            ":last-of-type": {
              borderBottom: `1px solid ${t.palette.divider}`,
              ".MuiDataGrid-cell--pinnedLeft": {
                borderBottom: `1px solid ${t.palette.divider}`,
              },
            },
            ".MuiDataGrid-cell": {
              ":first-of-type": {
                borderLeft: `1px solid ${t.palette.divider}`,
              },
              ":last-of-type": {
                borderRight: `1px solid ${t.palette.divider}`,
              },
            },
            "& .readonly-cell": {
              backgroundColor: grey[50],
            },
            "& .total-cell": {
              backgroundColor: grey[200],
              fontWeight: "bold",
            },
            "& .secondary-name-cell": {
              fontStyle: "oblique",
            },
          },
        })}
      />
    </PortfolioMonitoringInputTableContextProvider>
  );
};

export default MonthlyFinancialsInputTable;
