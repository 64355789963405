import { TabPanel } from "@mui/lab";
import { Box } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { parseSearchParamWithFallback } from "../../../../../shared/utilities/searchParamsHelper";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import PageTabs from "../../../common/PageTabs";
import InvestorPortalImpersonateButton from "../InvestorPortalImpersonateButton";
import UserConsentsSection from "../consents/UserConsentsSection";
import PortalDesignSettings from "../design/PortalDesignSettings";
import GeneralInvestorPortalSettings from "../general/GeneralInvestorPortalSettings";
import InvitationEmailSection from "../invitation-email/InvitationEmailSection";
import PortalPagesSettings from "../pages/PortalPagesSettings";
import PortalSupportInfo from "../support/PortalSupportInfo";
import WatermarkSettingsPage from "../watermark/WatermarkSettingsPage";
import InvestorPortalSyncStatusInfo from "./InvestorPortalSyncStatusInfo";

const tabs = [
  "general",
  "portal_design",
  "pages",
  "watermark",
  "user_consents",
  "support_info",
  "invitation_email",
] as const;

type Tab = (typeof tabs)[number];

const labels = [
  "Main Info",
  "Portal Design",
  "Pages",
  "Watermark",
  "User Consents",
  "Support Info",
  "Invitation Email",
];

export interface NavigationState {
  tab: Tab;
}

const InvestorPortalSettingsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<Tab>(parseSearchParamWithFallback(searchParams, "tab", tabs));

  const handleTabChange = (tab: Tab) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  return (
    <>
      <GeneralPageHeader title="Portal Settings" hideBorderLine>
        <InvestorPortalSyncStatusInfo />
        <Box width="0.5rem"></Box>
        <InvestorPortalImpersonateButton variant="outlined" color="secondary" />
      </GeneralPageHeader>
      <PageTabs tabs={tabs} labels={labels} value={selectedTab} onChange={handleTabChange}>
        <TabPanel value="general" sx={{ height: "100%", pt: 2 }}>
          <GeneralInvestorPortalSettings />
        </TabPanel>
        <TabPanel value="portal_design" sx={{ height: "100%", pt: 2 }}>
          <PortalDesignSettings />
        </TabPanel>
        <TabPanel value="pages" sx={{ height: "100%", pt: 2 }}>
          <PortalPagesSettings />
        </TabPanel>
        <TabPanel value="watermark" className="no-margin" sx={{ height: "100%", p: 0, "&.no-margin": { m: 0 } }}>
          <WatermarkSettingsPage />
        </TabPanel>
        <TabPanel value="user_consents" sx={{ pt: 2, height: "100%" }}>
          <UserConsentsSection />
        </TabPanel>
        <TabPanel value="support_info" sx={{ height: "100%", pt: 2 }}>
          <PortalSupportInfo />
        </TabPanel>
        <TabPanel value="invitation_email" sx={{ height: "100%", pt: 2 }}>
          <InvitationEmailSection />
        </TabPanel>
      </PageTabs>
    </>
  );
};

export default InvestorPortalSettingsPage;
