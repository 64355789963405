import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import BinIcon from "../../../../../shared/icons/BinIcon";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";

interface Props {
  consentName: string;
  onClose: () => void;
  onDeleted: (consentName: string) => void;
}

const deleteUserConsent = withErrorHandling(adminApi.deleteUserConsent);

const DeleteUserConsentDialog = ({ consentName, onClose, onDeleted }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);
    const [, error] = await deleteUserConsent(consentName);
    setSaving(false);

    if (error) {
      logError(error, "[DeleteUserConsentDialog] deleteUserConsent");
      sendNotificationError("Could not delete user consent");
      return;
    }

    sendNotification("User consent deleted successfully");
    onDeleted(consentName);
  };

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Delete User Consent</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              Delete user consent?
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              You're about to delete user consent <strong>{consentName}</strong>.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={saving} onClick={handleSubmit}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserConsentDialog;
