import { FormCellValue, FormRowExtensionRename } from "../../../../../api/types/dataCollectionTypes";

export type EditedFormValuesState = Record<string, EditedFormBlockValuesState>;

interface EditedFormBlockValuesState {
  formCellValues: FormCellValue[];
  formRowExtensionRenames: FormRowExtensionRename[];
}

export const getInitialEditedFormValuesState = (): EditedFormValuesState => ({});

// Selectors

export const isEditedFormValuesStateEmpty = (state: EditedFormValuesState): boolean =>
  Object.values(state).every(
    (values) => values.formCellValues.length === 0 && values.formRowExtensionRenames.length === 0
  );

// Actions

type StateAction = (state: EditedFormValuesState) => EditedFormValuesState;

const areFormCellValuesEqual = (a: FormCellValue, b: FormCellValue): boolean =>
  a.rowId === b.rowId && a.columnId === b.columnId && a.rowExtensionValue === b.rowExtensionValue;

export const addFormCellValueAction =
  (blockId: string, formCellValue: FormCellValue): StateAction =>
  (state) => {
    const prevBlockCellValues = state[blockId]?.formCellValues ?? [];
    const prevBlockExtensionRenames = state[blockId]?.formRowExtensionRenames ?? [];

    const valueToReplace = prevBlockCellValues.find((value) => areFormCellValuesEqual(value, formCellValue));

    const newBlockCellValues = valueToReplace
      ? prevBlockCellValues.map((value) => (value === valueToReplace ? formCellValue : value))
      : [...prevBlockCellValues, formCellValue];

    return {
      ...state,
      [blockId]: {
        formCellValues: newBlockCellValues,
        formRowExtensionRenames: prevBlockExtensionRenames,
      },
    };
  };

const isTransitiveRename = (firstRename: FormRowExtensionRename, secondRename: FormRowExtensionRename): boolean =>
  firstRename.rowId === secondRename.rowId && firstRename.newRowExtensionValue === secondRename.oldRowExtensionValue;

export const addFormRowExtensionRenameAction =
  (blockId: string, formRowExtensionRename: FormRowExtensionRename): StateAction =>
  (state) => {
    const prevBlockCellValues = state[blockId]?.formCellValues ?? [];
    const prevBlockExtensionRenames = state[blockId]?.formRowExtensionRenames ?? [];

    const renameToReplace = prevBlockExtensionRenames.find((r) => isTransitiveRename(r, formRowExtensionRename));

    const newBlockExtensionRenames = renameToReplace
      ? prevBlockExtensionRenames.map((rename) =>
          rename === renameToReplace
            ? {
                rowId: formRowExtensionRename.rowId,
                oldRowExtensionValue: renameToReplace.oldRowExtensionValue,
                newRowExtensionValue: formRowExtensionRename.newRowExtensionValue,
              }
            : rename
        )
      : [...prevBlockExtensionRenames, formRowExtensionRename];

    const newBlockCellValues = prevBlockCellValues.map((v) =>
      v.rowId === formRowExtensionRename.rowId && v.rowExtensionValue === formRowExtensionRename.oldRowExtensionValue
        ? { ...v, rowExtensionValue: formRowExtensionRename.newRowExtensionValue }
        : v
    );

    return {
      ...state,
      [blockId]: {
        formCellValues: newBlockCellValues,
        formRowExtensionRenames: newBlockExtensionRenames,
      },
    };
  };
