import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import LanguageIcon from "@mui/icons-material/LanguageRounded";
import { LinearProgress, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import { GridColDef } from "@mui/x-data-grid-premium";
import { convertISODate, convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import { DataCollectionRequestInfo } from "../../../../api/types/dataCollectionTypes";
import ActionsMenuButton from "../../../common/ActionsMenuButton";
import { useDataCollectionsPageContext } from "../DataCollectionsPageContext";
import DataRequestStatusTag from "./DataRequestStatusTag";
import { useDataRequestsListActionsContext } from "./DataRequestsListActionsContext";

const ActionsCell = ({ row }: { row: DataCollectionRequestInfo }) => {
  const { hasPermissionsToManage } = useDataCollectionsPageContext();
  const { onPublish, onDelete } = useDataRequestsListActionsContext();

  const canPublish = hasPermissionsToManage && row.status === "Draft";
  const canDelete = hasPermissionsToManage && row.status === "Draft";

  const menuItems = [];

  if (canPublish) {
    menuItems.push({
      icon: <LanguageIcon />,
      label: "Publish",
      onClick: () => onPublish(row),
    });
  }

  if (canDelete) {
    menuItems.push({
      icon: <DeleteIcon color="error" />,
      label: "Delete",
      onClick: () => onDelete(row),
    });
  }

  if (menuItems.length === 0) {
    return null;
  }

  return <ActionsMenuButton items={menuItems} />;
};

export const columnDefinitions: GridColDef<DataCollectionRequestInfo>[] = [
  {
    field: "name",
    headerName: "Name",
    cellClassName: "highlighted-action",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "templateName",
    headerName: "Request Template",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "reportingDate",
    headerName: "Reporting Date",
    flex: 0.5,
    minWidth: 112,
    valueFormatter: (value: string) => convertISODate(value),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    minWidth: 160,
    renderCell: ({ row }) => <DataRequestStatusTag status={row.status} />,
  },
  {
    field: "auditory",
    headerName: "Recipient Type",
    flex: 1,
    minWidth: 160,
    valueFormatter: (value: string) => (value ? autoFormatCamelCase(value) : ""),
  },
  {
    field: "publishedAt",
    headerName: "Published at",
    flex: 0.5,
    minWidth: 112,
    valueFormatter: (value: string | undefined) => convertISODateTime(value),
  },
  {
    field: "submittedSubmissions",
    headerName: "Submission Progress",
    width: 200,
    renderCell: ({ row }) =>
      row.status === "Draft" || row.totalSubmissions === 0 ? null : (
        <>
          <LinearProgress
            variant="determinate"
            value={(row.submittedSubmissions * 100) / row.totalSubmissions}
            sx={{ width: "8rem", backgroundColor: blueGrey[50] }}
          />
          <Typography noWrap ml={1}>
            {row.submittedSubmissions}/{row.totalSubmissions}
          </Typography>
        </>
      ),
  },
  {
    field: "actions",
    headerName: "",
    width: 80,
    align: "right",
    cellClassName: "grid-row-actions",
    renderCell: ({ row }) => <ActionsCell row={row} />,
  },
];
