import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import RenameIcon from "@mui/icons-material/DriveFileRenameOutline";
import { GridColDef } from "@mui/x-data-grid-premium";
import FundraisingStatusTag from "../../../../../shared/components/FundraisingStatusTag";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { FundraisingInfo } from "../../../../api/types/fundraisingTypes";
import ActionsMenuButton from "../../../common/ActionsMenuButton";
import { useFundraisingsPageContext } from "./FundraisingsPageContext";
import { getAvailableStatusTransitions } from "./fundraisingStatusHelper";

const ActionsCell = ({ row }: { row: FundraisingInfo }) => {
  const { hasEditPermissions, onRename, onStatusChange, onDelete } = useFundraisingsPageContext();

  if (!hasEditPermissions) {
    return null;
  }

  return (
    <ActionsMenuButton
      items={[
        {
          label: "Rename",
          icon: <RenameIcon />,
          onClick: () => onRename(row.id, row.name),
        },
        ...getAvailableStatusTransitions(row.status).map((transition) => ({
          label: transition.label,
          icon: <transition.Icon color="secondary" />,
          onClick: () => onStatusChange(row, transition),
          disabled: (transition.to === "Preview" || transition.to === "Live") && !row.isReadyForPublishing,
        })),
        {
          label: "Delete",
          icon: <DeleteIcon color="error" />,
          onClick: () => onDelete(row.id, row.name),
          disabled: row.status === "Live",
        },
      ]}
    />
  );
};

export const columnDefinitions: GridColDef<FundraisingInfo>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1.5,
    cellClassName: "highlighted-action",
  },
  {
    field: "fundName",
    headerName: "Fund",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    renderCell: ({ row }) => <FundraisingStatusTag status={row.status} />,
  },
  {
    field: "documentCount",
    headerName: "Documents",
    type: "number",
    flex: 0.5,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    flex: 1,
    valueFormatter: (value: string) => (value ? convertISODateTime(value) : ""),
  },
  {
    field: "createdByUserName",
    headerName: "Created by",
    flex: 1,
  },
  {
    field: "actions",
    headerName: "",
    width: 144,
    align: "right",
    cellClassName: "grid-row-actions",
    renderCell: ({ row }) => <ActionsCell row={row} />,
  },
];
