import { Typography } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { DataCollectionRequestInfo } from "../../../../api/types/dataCollectionTypes";
import { pageRoutes } from "../../../../routes";
import { columnDefinitions } from "./dataRequestsGridDataProvider";

interface Props {
  rows: DataCollectionRequestInfo[];
  isLoading?: boolean;
  onRowsScrollEnd: () => void;
}

const DataRequestsGrid = ({ rows, isLoading, onRowsScrollEnd }: Props) => {
  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = ({ row, field }) => {
    if (field !== "actions") {
      navigate(`${pageRoutes.dataRequests}/${row.id}`);
    }
  };

  return (
    <DataGrid<DataCollectionRequestInfo>
      rows={rows}
      columns={columnDefinitions}
      loading={isLoading}
      columnHeaderHeight={36}
      rowHeight={40}
      selectableRows
      onRowsScrollEnd={onRowsScrollEnd}
      onCellClick={handleCellClick}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableColumnSorting
      disableRowSelectionOnClick
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="text.secondary">No data requests</Typography>
          </CenteredWrapper>
        ),
      }}
    />
  );
};

export default DataRequestsGrid;
