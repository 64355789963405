import { Typography } from "@mui/material";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { DataCollectionRequestTemplateVersion } from "../../../../api/types/dataCollectionTypes";
import { columnDefinitions } from "./dataRequestTemplatesGridDataProvider";

interface Props {
  rows: DataCollectionRequestTemplateVersion[];
}

const DataRequestTemplatesGrid = ({ rows }: Props) => {
  return (
    <DataGrid<DataCollectionRequestTemplateVersion>
      rows={rows}
      columns={columnDefinitions}
      columnHeaderHeight={36}
      rowHeight={40}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableColumnSorting
      disableRowSelectionOnClick
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="text.secondary">No request templates</Typography>
          </CenteredWrapper>
        ),
      }}
    />
  );
};

export default DataRequestTemplatesGrid;
