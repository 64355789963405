import { Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { pageRoutes } from "../../../../routes";

const NavigateToInvitationEmailSettings = () => {
  const theme = useTheme();

  return (
    <Stack mt={2} width="100%" alignItems="center">
      <Typography>
        {"Click "}
        <Link
          style={{ color: theme.palette.primary.main, textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
          to={`../../${pageRoutes.investorRelations}/${pageRoutes.investorPortalSettings}?tab=invitation_email`}
        >
          here
        </Link>
        {" to view and or adjust the email template."}
      </Typography>
    </Stack>
  );
};

export default NavigateToInvitationEmailSettings;
