import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Stack, Typography } from "@mui/material";
import objectHash from "object-hash";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import useToggleState from "../../../../../shared/hooks/useToggleState";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataCollectionRequestInfo } from "../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../context/ClientContext";
import {
  firstPageAction,
  getInitialPaginatedItemsState,
  getPagingParams,
  loadItemsAction,
  nextPageAction,
} from "../../../../state/paginatedState";
import storage from "../../../../storage/storage";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { createReducer, getInitialState } from "../../../common/filters/filterState";
import { emptySearchFilterDefinition } from "../../../common/filters/handlers/filterHandlers";
import { useDataCollectionsPageContext } from "../DataCollectionsPageContext";
import { dataCollectionsNavigationViewsCaptionMap } from "../dataCollectionsPageTypes";
import DataRequestsGrid from "./DataRequestsGrid";
import { createSelectOptionsResolver, filterDefinitions, getFilterRequestPayload } from "./dataRequestsGridFilters";
import { DataRequestsListActionsContextProvider } from "./DataRequestsListActionsContext";
import DeleteDataRequestDialog from "./dialogs/DeleteDataRequestDialog";
import EditDataRequestDialog from "./dialogs/EditDataRequestDialog";
import PublishDataRequestDialog from "./dialogs/PublishDataRequestDialog";

interface DialogState {
  openDialog?: "new_request" | "edit_request" | "publish_request" | "delete_request";
  editedDataRequest?: DataCollectionRequestInfo;
}

const DataRequestsList = () => {
  const { clientCode } = useClientContext();
  const { hasPermissionsToManage, templates } = useDataCollectionsPageContext();

  const [pageState, setPageState] = useState(getInitialPaginatedItemsState<DataCollectionRequestInfo>());

  const [dialogState, setDialogState] = useState<DialogState>({});

  const [filterState, dispatchFilters] = useReducer(
    createReducer<DataCollectionRequestInfo>(),
    getInitialState(
      `${clientCode}_data_collection_requests_filter_v1`,
      filterDefinitions,
      emptySearchFilterDefinition(),
      []
    )
  );

  const [updateTrigger, toggleUpdateTrigger] = useToggleState(false);

  const resetPageOnFiltersChange = useCallback(() => {
    if (pageState.page !== 0) {
      setPageState(firstPageAction());
    } else {
      toggleUpdateTrigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectHash(getFilterRequestPayload(filterState))]);

  useEffect(() => {
    resetPageOnFiltersChange();
  }, [resetPageOnFiltersChange]);

  const searchDataCollectionRequests = useCallback(
    () =>
      adminApi.searchDataCollectionRequests({
        paging: getPagingParams(pageState.page),
        ...getFilterRequestPayload(filterState),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageState.page, updateTrigger]
  );

  const [, fetchError, { isFetching, fetch: fetchDataCollectionRequests }] = useFetch(
    searchDataCollectionRequests,
    (resp) => {
      setPageState(loadItemsAction(resp));
    }
  );

  const handleRowsScrollEnd = useCallback(() => {
    setTimeout(() => setPageState(nextPageAction()), 100);
  }, []);

  const getSelectOptions = useMemo(() => createSelectOptionsResolver(templates), [templates]);

  if (fetchError) {
    logError(fetchError, "[DataCollectionRequestsList] searchDataCollectionRequests");
    storage.clearFilterState(`${clientCode}_data_collection_requests_filter_v1`);
    return <DataLoadingFailed title="Could not load data collection requests" />;
  }

  const handleRefresh = () => {
    if (pageState.page === 0) {
      fetchDataCollectionRequests();
    } else {
      setPageState(firstPageAction());
    }
  };

  const closeDialogAndRefresh = () => {
    setDialogState({});
    handleRefresh();
  };

  const handlePublishDataRequest = (dataRequest: DataCollectionRequestInfo) => {
    setDialogState({ openDialog: "publish_request", editedDataRequest: dataRequest });
  };

  const handleDeleteDataRequest = (dataRequest: DataCollectionRequestInfo) => {
    setDialogState({ openDialog: "delete_request", editedDataRequest: dataRequest });
  };

  return (
    <FilterContextProvider
      filterState={filterState}
      dispatchFilters={dispatchFilters}
      getSelectOptions={getSelectOptions}
    >
      <DataRequestsListActionsContextProvider onPublish={handlePublishDataRequest} onDelete={handleDeleteDataRequest}>
        <Box flex={1} display="flex" flexDirection="column">
          <Box py={2} px={3} borderBottom={1} borderColor="divider" display="flex" justifyContent="space-between">
            <Typography variant="h6">{dataCollectionsNavigationViewsCaptionMap["data_collection_requests"]}</Typography>
            {hasPermissionsToManage && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setDialogState({ openDialog: "new_request" })}
              >
                Add New
              </Button>
            )}
          </Box>

          <Stack width="100%" spacing={2} pt={2.5} px={3} flex={1}>
            <FiltersPanel
              totalCount={pageState.totalRecords}
              recordCount={pageState.items.length}
              isLoading={isFetching}
              onRefresh={handleRefresh}
            />

            <DataRequestsGrid rows={pageState.items} isLoading={isFetching} onRowsScrollEnd={handleRowsScrollEnd} />
          </Stack>
        </Box>

        {dialogState.openDialog === "new_request" && (
          <EditDataRequestDialog
            templates={templates}
            onClose={() => setDialogState({})}
            onSaved={closeDialogAndRefresh}
          />
        )}

        {dialogState.editedDataRequest && (
          <PublishDataRequestDialog
            open={dialogState.openDialog === "publish_request"}
            onClose={() => setDialogState({})}
            onPublished={closeDialogAndRefresh}
            dataRequestId={dialogState.editedDataRequest.id}
            dataRequestName={dialogState.editedDataRequest.name}
          />
        )}

        {dialogState.editedDataRequest && (
          <DeleteDataRequestDialog
            open={dialogState.openDialog === "delete_request"}
            onClose={() => setDialogState({})}
            onDeleted={closeDialogAndRefresh}
            dataRequestId={dialogState.editedDataRequest.id}
            dataRequestName={dialogState.editedDataRequest.name}
          />
        )}
      </DataRequestsListActionsContextProvider>
    </FilterContextProvider>
  );
};

export default DataRequestsList;
