import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import BinIcon from "../../../../../../shared/icons/BinIcon";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";

const deleteDataCollectionRequest = withErrorHandling(adminApi.deleteDataCollectionRequest);

interface Props {
  dataRequestId: string;
  dataRequestName: string;
  open: boolean;
  onClose: () => void;
  onDeleted: (id: string) => void;
}

const DeleteDataRequestDialog = ({ open, dataRequestId, dataRequestName, onClose, onDeleted }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const [, error] = await deleteDataCollectionRequest(dataRequestId);
    setLoading(false);

    if (error) {
      logError(error, "[DeleteDataRequestDialog] deleteDataCollectionRequest");
      sendNotificationError("Could not delete data request");
      return;
    }

    sendNotification("Data request deleted successfully");
    onDeleted(dataRequestId);
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Delete Data Request</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Delete {dataRequestName}?</Typography>
            <Typography color="text.secondary" textAlign="center">
              You're about to delete this data request. This action is permanent and can't be undone.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={loading} onClick={handleSubmit}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDataRequestDialog;
