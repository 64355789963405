import { Stack, Typography } from "@mui/material";
import {
  MonthlyFinancialsBlockAttributes,
  MonthlyFinancialsBlockContent,
} from "../../../../../../api/types/inputFormTypes";
import BlockSection from "../BlockSection";
import MonthlyFinancialsInputTable from "./MonthlyFinancialsInputTable";

interface Props {
  blockId: string;
  attributes: MonthlyFinancialsBlockAttributes;
  content: MonthlyFinancialsBlockContent;
}

const MonthlyFinancialsFormBlock = ({ blockId, content }: Props) => {
  return (
    <Stack flex={1} spacing={2.5}>
      {content.tables.map((tableBlock, index) => (
        <BlockSection key={index}>
          <Typography variant="h6">{tableBlock.name}</Typography>
          <MonthlyFinancialsInputTable
            blockId={blockId}
            columnDefinitions={tableBlock.columns}
            rowDefinitions={tableBlock.rows}
          />
        </BlockSection>
      ))}
    </Stack>
  );
};

export default MonthlyFinancialsFormBlock;
