import { GridColDef } from "@mui/x-data-grid-premium";
import { DataCollectionRequestTemplateVersion, ReportingPeriod } from "../../../../api/types/dataCollectionTypes";
import { reportingPeriodCaptionsMap } from "../../../../utilities/enumCaptions";

export const columnDefinitions: GridColDef<DataCollectionRequestTemplateVersion>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    minWidth: 160,
  },
  {
    field: "reportingPeriod",
    headerName: "Period",
    flex: 1,
    minWidth: 160,
    valueFormatter: (value: ReportingPeriod) => (value ? reportingPeriodCaptionsMap[value] : ""),
  },
  {
    field: "scenarioName",
    headerName: "Scenario",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "recipientObjectIds",
    headerName: "Companies",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 80,
    valueFormatter: (value: string[] | undefined) => (value?.length ?? 0).toLocaleString(),
  },
  {
    field: "actionsPlaceholder",
    headerName: "",
    width: 80,
  },
];
