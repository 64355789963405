import { DescriptiveDate, last12Months } from "../../../../shared/utilities/dateFilterHelper";
import { DocumentCollection } from "../../../api/types/documentCollectionTypes";
import storage from "../../../storage/storage";
import { createSearchFilter } from "./searchFilter";

export interface DocumentCollectionsFilterState {
  allItems: DocumentCollection[];
  filteredItems: DocumentCollection[];
  searchTerm: string;
  dateRange: DescriptiveDate;
  allFunds: string[];
  selectedFunds: string[];
}

type Action =
  | {
      type: "LOAD_DATA";
      items: DocumentCollection[];
    }
  | {
      type: "SEARCH";
      searchTerm: string;
    }
  | {
      type: "FILTER_FUNDS";
      selectedFunds: string[];
    }
  | {
      type: "SET_DATE_RANGE";
      dateRange: DescriptiveDate;
      clientCode: string;
    }
  | {
      type: "CLEAR_FILTERS";
      clientCode: string;
    };

const applySearch = createSearchFilter<DocumentCollection>(3, (c) => [c.name, c.fundName, ...c.categories]);

const applyFilters = (newState: DocumentCollectionsFilterState): DocumentCollectionsFilterState => {
  const { selectedFunds, searchTerm } = newState;

  let filteredItems = newState.allItems;

  filteredItems =
    selectedFunds.length > 0
      ? filteredItems.filter((item) => item.fundName && selectedFunds.includes(item.fundName))
      : filteredItems;

  filteredItems = applySearch(filteredItems, searchTerm);

  return { ...newState, filteredItems };
};

const getAllFunds = (items: DocumentCollection[]) =>
  [
    ...new Set(
      items
        .map((item) => item.fundName)
        .filter(Boolean)
        .sort()
    ),
  ] as string[];

export const defaultDateRange = last12Months;

export const getInitialState = (clientCode: string): DocumentCollectionsFilterState => ({
  allItems: [],
  filteredItems: [],
  searchTerm: "",
  allFunds: [],
  selectedFunds: [],
  dateRange: storage.getDocumentCollectionsDateRange(clientCode) ?? defaultDateRange,
});

export const reducer = (state: DocumentCollectionsFilterState, action: Action): DocumentCollectionsFilterState => {
  switch (action.type) {
    case "LOAD_DATA": {
      return applyFilters({
        ...state,
        allItems: action.items,
        allFunds: getAllFunds(action.items),
      });
    }
    case "SEARCH": {
      return applyFilters({ ...state, searchTerm: action.searchTerm });
    }
    case "FILTER_FUNDS": {
      return applyFilters({ ...state, selectedFunds: action.selectedFunds });
    }
    case "SET_DATE_RANGE": {
      storage.saveDocumentCollectionsDateRange(action.clientCode, action.dateRange);
      return { ...state, dateRange: action.dateRange };
    }
    case "CLEAR_FILTERS": {
      storage.saveDocumentCollectionsDateRange(action.clientCode, defaultDateRange);
      return {
        ...state,
        dateRange: defaultDateRange,
        selectedFunds: [],
        filteredItems: applySearch(state.allItems, state.searchTerm),
      };
    }
    default: {
      return state;
    }
  }
};
