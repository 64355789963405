import { ConsentAgreement } from "./types";

export interface ConsentAgreementsState {
  allAgreements: ConsentAgreement[];
  acceptedAgreements: ConsentAgreement[];
  agreeToNews: boolean;
  nextAgreementToAccept?: ConsentAgreement;
  isLast: boolean;
  showNewsConsent: boolean;
}

type Action = {
  type: "AGREEMENT_ACCEPTED";
  payload: {
    agreeToNews?: boolean;
  };
};

export const getInitialState = (consentAgreements: ConsentAgreement[]): ConsentAgreementsState => ({
  allAgreements: [...consentAgreements],
  acceptedAgreements: [],
  agreeToNews: false,
  nextAgreementToAccept: consentAgreements[0],
  isLast: consentAgreements.length <= 1,
  showNewsConsent: consentAgreements[0]?.type === "InvestorPortalBasicAgreement",
});

const getAgreementKey = (consentAgreement: ConsentAgreement) =>
  `${consentAgreement.type}_${consentAgreement.name || ""}`;

export const reducer = (state: ConsentAgreementsState, { type, payload }: Action): ConsentAgreementsState => {
  switch (type) {
    case "AGREEMENT_ACCEPTED": {
      if (!state.nextAgreementToAccept) {
        return state;
      }

      const acceptedAgreements = [...state.acceptedAgreements, state.nextAgreementToAccept];
      const acceptedAreementKeys = acceptedAgreements.map(getAgreementKey);
      const agreementsToAccept = state.allAgreements.filter((a) => !acceptedAreementKeys.includes(getAgreementKey(a)));
      return {
        allAgreements: state.allAgreements,
        acceptedAgreements,
        agreeToNews: payload.agreeToNews ?? state.agreeToNews,
        nextAgreementToAccept: agreementsToAccept[0],
        isLast: agreementsToAccept.length <= 1,
        showNewsConsent: agreementsToAccept[0]?.type === "InvestorPortalBasicAgreement",
      };
    }
    default: {
      return state;
    }
  }
};
