import LanguageIcon from "@mui/icons-material/LanguageRounded";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";
import { DataCollectionRequestDetails } from "../../../../../api/types/dataCollectionTypes";
import CombinedPaperIcon from "../../../../common/CombinedPaperIcon";

const publishDataCollectionRequest = withErrorHandling(adminApi.publishDataCollectionRequest);

interface Props {
  dataRequestId: string;
  dataRequestName: string;
  open: boolean;
  onClose: () => void;
  onPublished: (dataRequest: DataCollectionRequestDetails) => void;
}

const PublishDataRequestDialog = ({ open, dataRequestId, dataRequestName, onClose, onPublished }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const [resp, error] = await publishDataCollectionRequest(dataRequestId);
    setLoading(false);

    if (error) {
      logError(error, "[PublishDataCollectionRequestDialog] publishDataCollectionRequest");
      sendNotificationError("Could not publish data request");
      return;
    }

    sendNotification("Data request published successfully");
    onPublished(resp);
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Publish Data Request</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <CombinedPaperIcon Icon={LanguageIcon} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Publish {dataRequestName}</Typography>
            <Typography color="text.secondary" textAlign="center">
              You're about to publish this data request and start collecting submissions.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={loading} onClick={handleSubmit}>
          Publish
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PublishDataRequestDialog;
