import { Divider, Grid, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { DataCollectionRequestDetails } from "../../../../../api/types/dataCollectionTypes";
import { reportingPeriodCaptionsMap } from "../../../../../utilities/enumCaptions";

interface Props {
  dataRequest: DataCollectionRequestDetails;
}

const DataRequestInfoSection = ({ dataRequest }: Props) => {
  return (
    <Paper variant="outlined" sx={{ py: 2, pl: 2 }}>
      <Grid container spacing={2.5}>
        <Grid item xs={1.5}>
          <Stack spacing={0.5}>
            <Typography variant="caption">Scenario</Typography>
            <Typography variant="subtitle2">{dataRequest.templateScenario}</Typography>
          </Stack>
        </Grid>

        <Grid item xs="auto">
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={1.5}>
          <Stack spacing={0.5}>
            <Typography variant="caption">Period</Typography>
            <Typography variant="subtitle2">
              {reportingPeriodCaptionsMap[dataRequest.templateReportingPeriod]}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs="auto">
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={1.5}>
          <Stack spacing={0.5}>
            <Typography variant="caption">Template</Typography>
            <Typography variant="subtitle2">{dataRequest.templateName}</Typography>
          </Stack>
        </Grid>

        <Grid item xs="auto">
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={1.5}>
          <Stack spacing={0.5}>
            <Typography variant="caption">Reporting Date</Typography>
            <Typography variant="subtitle2">{convertISODate(dataRequest.reportingDate)}</Typography>
          </Stack>
        </Grid>

        <Grid item xs="auto">
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={5}>
          <Stack spacing={1}>
            <Typography variant="caption">Progress</Typography>
            <Stack direction="row" spacing={1} alignItems="center" width="100%">
              {dataRequest.submissions.length && (
                <LinearProgress
                  variant="determinate"
                  value={(dataRequest.submittedSubmissions * 100) / dataRequest.submissions.length}
                  sx={{ width: "calc(100% - 32px)", backgroundColor: blueGrey[50] }}
                />
              )}
              <Typography noWrap>
                {dataRequest.submittedSubmissions}/{dataRequest.submissions.length}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DataRequestInfoSection;
